import React from 'react';
import { motion } from 'framer-motion';

const lectures = [
    {
        id: 1,
        title: 'Introduction to Stock Market',
        videoUrl: 'https://www.example.com/video1.mp4',
        isLocked: false,
    },
    {
        id: 2,
        title: 'Technical Analysis Basics',
        videoUrl: 'https://www.example.com/video2.mp4',
        isLocked: true,
    },
    {
        id: 3,
        title: 'Fundamental Analysis Explained',
        videoUrl: 'https://www.example.com/video3.mp4',
        isLocked: false,
    },
    {
        id: 4,
        title: 'Advanced Trading Strategies',
        videoUrl: 'https://www.example.com/video4.mp4',
        isLocked: true,
    },
    {
        id: 5,
        title: 'Risk Management Techniques',
        videoUrl: 'https://www.example.com/video5.mp4',
        isLocked: false,
    },
];

const LecturesPage = () => {
    const handleLockClick = (lecture) => {
        // Redirect to a different page or URL when the lock overlay is clicked
        window.location.href = `/unlock/${lecture.id}`; // Change this to your desired URL
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold text-center mb-8">Lectures</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {lectures.map((lecture) => (
                    <motion.div
                        key={lecture.id}
                        className={`bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 ${lecture.isLocked ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105'
                            }`}
                        whileHover={{ scale: lecture.isLocked ? 1 : 1.05 }}
                    >
                        <div className="relative">
                            <video
                                className="w-full h-48 object-cover"
                                controls
                                src={lecture.isLocked ? '' : lecture.videoUrl}
                                poster="https://via.placeholder.com/300" // Placeholder image
                            >
                                Your browser does not support the video tag.
                            </video>
                            {lecture.isLocked && (
                                <div
                                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 cursor-pointer"
                                    onClick={() => handleLockClick(lecture)}
                                >
                                    <span className="text-white font-bold">Locked - Click to Unlock</span>
                                </div>
                            )}
                        </div>
                        <div className="p-4">
                            <h2 className="text-lg font-semibold">{lecture.title}</h2>
                            {lecture.isLocked ? (
                                <button className="mt-2 bg-blue-600 text-white py-1 px-3 rounded hover:bg-blue-700">
                                    Unlock for $10
                                </button>
                            ) : (
                                <button className="mt-2 bg-green-600 text-white py-1 px-3 rounded hover:bg-green-700">
                                    Watch Now
                                </button>
                            )}
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default LecturesPage;