import React, { useState } from "react";
import { Link } from "react-router-dom";
// import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white shadow-md sticky top-0 w-full z-10 ">
      <div className="container mx-auto flex justify-between items-center min-[320px]:px-8 sm:px-8 md:px-12 lg:px-16 py-2">
        <Link className="text-2xl font-bold text-gray-800" to="/">
          <img
            alt="logo"
            src={"/assets/company_logo.png"}
            className="w-10 h-10 "
          />
        </Link>
        <div className="md:hidden min-[320px]:block font-semibold">The Chartistt</div>
        <div className="hidden md:flex space-x-4">
          <Link to={"/"} className="relative inline-block text-gray-800 hover:text-blue-600 transition py-2 group">
            Home
            <span className="absolute left-0 bottom-0 w-full h-0.5 bg-red-600 transform scale-x-0 transition-transform duration-300 ease-in-out group-hover:scale-x-100 rounded-lg"></span>
          </Link>
          <a href="#about" className="relative inline-block text-gray-800 hover:text-blue-600 transition py-2 group">
            About
            <span className="absolute left-0 bottom-0 w-full h-0.5 bg-red-600 transform scale-x-0 transition-transform duration-300 ease-in-out group-hover:scale-x-100 rounded-lg"></span>
          </a>
          <Link to={"/lectures"} className="relative inline-block text-gray-800 hover:text-blue-600 transition py-2 group">
            Lectures
            <span className="absolute left-0 bottom-0 w-full h-0.5 bg-red-600 transform scale-x-0 transition-transform duration-300 ease-in-out group-hover:scale-x-100 rounded-lg"></span>
          </Link>
          <a href="#courses" className="relative inline-block text-gray-800 hover:text-blue-600 transition py-2 group">
            Courses
            <span className="absolute left-0 bottom-0 w-full h-0.5 bg-red-600 transform scale-x-0 transition-transform duration-300 ease-in-out group-hover:scale-x-100 rounded-lg"></span>
          </a>
          <a href="#contact" className="relative inline-block text-gray-800 hover:text-blue-600 transition py-2 group">
            Contact
            <span className="absolute left-0 bottom-0 w-full h-0.5 bg-red-600 transform scale-x-0 transition-transform duration-300 ease-in-out group-hover:scale-x-100 rounded-lg"></span>
          </a>
        </div>
        <button
          className="md:hidden text-gray-800 focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {isOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            )}
          </svg>
        </button>
      </div>
      <div
        className={`md:hidden ${isOpen ? "block" : "hidden"} bg-white`}
      >
        <div className="flex flex-col space-y-2 p-4">
          <Link to="/" className="text-gray-800 hover:text-blue-600 transition">
            Home
          </Link>
          <a href="#about" className="text-gray-800 hover:text-blue-600 transition">
            About
          </a>
          <Link to="/lectures" className="text-gray-800 hover:text-blue-600 transition">
            Lectures
          </Link>
          <a href="#courses" className="text-gray-800 hover:text-blue-600 transition">
            Courses
          </a>
          <a href="#contact" className="text-gray-800 hover:text-blue-600 transition">
            Contact
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
