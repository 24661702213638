import React, { useState } from "react";
import { motion } from "framer-motion";
import { LuBadgePercent } from "react-icons/lu";
import "./couese.css";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../utils/firebase";

const Course = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeCourse, setActiveCourse] = useState([
    {
      link: "https://gmhmw.courses.store/611001",
      title: `369 Club By The Chartistt
  `,
      categories: [0, 3, 5],
      tags: ["VIDEOS", "PDFS"],
      offer: "₹ 8001",
      courseImg: 7,
      btn_name: "369_club_course",
    },
    {
      link: "https://gmhmw.courses.store/561553",
      title: "Wealth Creation Using Astro Moon & Numbers Science",
      tags: ["FREE CONTENT", "VIDEOS", "FILES"],
      offer: "50%",
      categories: [0, 3, 4],
      courseImg: 2,
      btn_name: "astro_moon",
    },
    {
      link: "https://gmhmw.courses.store/586752",
      title: "COMPLETE 30 DAY TRADING PROGRAM (NOVEMBER BATCH)",
      tags: ["VIDEOS", "FILES", "LIVE"],
      offer: "₹ 4164",
      categories: [0, 3],
      courseImg: 3,
      btn_name: "november_batch",
    },
  ]);

  const data = [
    {
      link: "https://gmhmw.courses.store/611001",
      title: `369 Club By The Chartistt
  `,
      categories: [0, 3, 5],
      tags: ["VIDEOS", "PDFS"],
      offer: "₹ 8001",
      courseImg: 7,
      btn_name: "369_club_course",
    },
    {
      link: "https://gmhmw.courses.store/587235",
      title: `3 in 1 Combo Course
  `,
      categories: [4, 2, 5, 6],
      tags: ["TESTS", "VIDEOS", "FILES"],
      offer: "",
      courseImg: 1,
      btn_name: "3_in_1_combo_course",
    },
    {
      link: "https://gmhmw.courses.store/561553",
      title: "Wealth Creation Using Astro Moon & Numbers Science",
      tags: ["FREE CONTENT", "VIDEOS", "FILES"],
      offer: "50%",
      categories: [0, 3, 4],
      courseImg: 2,
      btn_name: "astro_moon",
    },
    {
      link: "https://gmhmw.courses.store/586752",
      title: "COMPLETE 30 DAY TRADING PROGRAM (NOVEMBER BATCH)",
      tags: ["VIDEOS", "FILES", "LIVE"],
      offer: "₹ 4164",
      categories: [0, 3],
      courseImg: 3,
      btn_name: "november_batch",
    },
    {
      link: "https://gmhmw.courses.store/537497",
      title: "Independent Swing & Momentum trader",
      tags: ["VIDEOS", "FILES"],
      offer: "₹ 4154",
      categories: [1, 4],
      courseImg: 4,
      btn_name: "swing_momentum",
    },
  ];

  // Animation variants
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const CourseTabs = () => {
    const tabs = [
      "Trending",
      "Swing trading",
      "Special combo",
      "Indicator",
      "Live learning batch ",
      "Forex",
      "Lifetime batch",
    ];

    return (
      <div className="max-w-screen mx-auto mb-2">
        <div className="flex space-x-2 overflow-x-auto whitespace-nowrap">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`flex-shrink-0 py-2 px-4 text-center rounded-lg focus:outline-none transition duration-300 
                            ${
                              activeTab === index
                                ? "bg-blue-700 text-white"
                                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                            } min-[320px]:text-xs md:text-md`}
              onClick={() => {
                logEvent(analytics, `${tab.replaceAll(" ", "_")}_category`, {
                  button_name: tab,
                });
                setActiveTab(index);
                const newData = data.filter((ele) =>
                  ele.categories.includes(index)
                );
                setActiveCourse(newData);
              }}
            >
              {tab}
            </button>
          ))}
        </div>
        {/* Uncomment the following block if you want to display content related to the active tab */}
        {/* <div className="mt-4 p-4 border-t border-gray-300"> */}
        {/* <h2 className="font-semibold">{tabs[activeTab]}</h2> */}
        {/* <p>Content for {tabs[activeTab]} goes here.</p> */}
        {/* </div> */}
      </div>
    );
  };

  const CourseCard = ({ data, index }) => {
    return (
      <div className="col p-2">
        {" "}
        {/* Reduced padding for smaller screens */}
        <motion.div
          className="shadow-lg rounded-xl overflow-hidden h-[450px] w-full" // Fixed height and full width
          initial="hidden"
          whileInView="visible"
          variants={cardVariants}
          viewport={{ once: true, amount: 0.2 }} // Trigger when 20% of card is in view
          transition={{ duration: 0.5, ease: "easeOut" }}
          whileHover={{
            scale: 1.05,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div className="w-full">
            <img
              src={`/assets/courseImg${data.courseImg}.jpg`}
              alt="Course"
              className="h-[250px] w-full" // Image maintains aspect ratio
            />
          </div>
          <div className="p-4 flex flex-col gap-y-2 h-[200px] justify-between">
            {" "}
            {/* Set a fixed height for content */}
            <div className="flex flex-wrap gap-x-2 gap-y-1">
              {" "}
              {/* Use flex-wrap for tags */}
              {data?.tags?.map((ele, idx) => (
                <div
                  key={idx}
                  className="rounded-sm px-2 py-1 bg-gray-100 text-black text-xs font-semibold"
                >
                  {" "}
                  {/* Changed text size */}
                  {ele}
                </div>
              ))}
            </div>
            <div className="font-bold leading-5 text-base truncate">
              {data.title}
            </div>{" "}
            {/* Added truncate to prevent overflow */}
            <div className="text-sm text-orange-600">
              {data.offer.length > 0 && (
                <div className="flex flex-row items-center gap-x-1">
                  <LuBadgePercent />
                  {`Extra ${data.offer} coupon discount`}
                </div>
              )}
            </div>
            <a
              href={data.link}
              className="text-white w-full bg-blue-700 py-2 rounded-lg text-center hover:shadow-md hover:bg-blue-500 transition-all duration-300"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                logEvent(analytics, `${data.btn_name}`, {
                  button_name: data.title,
                });
              }}
            >
              Buy Now
            </a>
          </div>
        </motion.div>
      </div>
    );
  };

  return (
    <section id="courses" className="pb-4">
      <motion.div
        className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-center py-8"
        initial="hidden"
        whileInView="visible"
        variants={cardVariants}
        viewport={{ once: true, amount: 0.2 }} // Trigger when 20% of card is in view
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        Our Courses
      </motion.div>
      <CourseTabs />
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-2">
        {activeCourse.map((ele, idx) => (
          <CourseCard key={idx} data={ele} index={idx} />
        ))}
      </div>
    </section>
  );
};

export default Course;
