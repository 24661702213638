import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Homepage from "./pages/Homepage";
import DisclaimerModal from "./components/DisclamerDialogue/Disclamer";
import Footer from "./components/Footer/Footer";
import VideoPopup from "./components/VideoAdd/VideoPopup";
import LecturesPage from "./pages/LecturesPage";


const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <DisclaimerModal />
        {/* <VideoPopup /> */}
        <Routes>
          <Route path="/" element={<Homepage />} /> {/* Use element prop instead of component */}
          <Route path="/lectures" element={<LecturesPage />} /> {/* Route for LecturesPage */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
